"use strict";
import { defineMessages } from "react-intl";
export const dataPointsActionNames = defineMessages({
  archived: {
    id: "common.archive",
    defaultMessage: "Archive"
  },
  unarchive: {
    id: "common.unarchive",
    defaultMessage: "Unarchive"
  },
  update: {
    id: "common.update",
    defaultMessage: "update"
  },
  create: {
    id: "common.create",
    defaultMessage: "create"
  },
  enabled: {
    id: "common.enable",
    defaultMessage: "Enable"
  },
  disabled: {
    id: "common.disable",
    defaultMessage: "Disable"
  },
  isMaxRate: {
    id: "dataPoint.config.isMaxRate",
    defaultMessage: "Use Max Rate"
  }
});
export const validationFileMessages = defineMessages({
  defaultValidation: {
    id: "validation.file.error.default_message",
    defaultMessage: "An error has occurred. Please contact the support team for assistance."
  },
  exceededMaxUploadFileLimit: {
    id: "validation.file.upload.exceeded_maximum_file_upload_limit",
    defaultMessage: "Please note: No file uploaded. You've exceeded the maximum file limit"
  },
  unsupportedFileTypeDetected: {
    id: "validation.file.upload.unsupported_file_type_detected",
    defaultMessage: "Unsupported file type detected. Upload only supported files"
  },
  fileSizeExceedsTheLimit: {
    id: "validation.file.upload.file_size_exceeds_the_limit",
    defaultMessage: "File size exceeds the limit of"
  },
  excelDoesNotMatchDataPointSchema: {
    id: "validation.file.upload.excel_does_not_match_data_point_schema",
    defaultMessage: "Excel attributes do not match with dataPoint schema!"
  }
});
